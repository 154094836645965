import { render, staticRenderFns } from "./quick-intro.vue?vue&type=template&id=49cf46aa&scoped=true&"
import script from "./quick-intro.vue?vue&type=script&lang=js&"
export * from "./quick-intro.vue?vue&type=script&lang=js&"
import style0 from "./quick-intro.vue?vue&type=style&index=0&id=49cf46aa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49cf46aa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBtn,VIcon,VImg,VSubheader})
