<template>
  <div id="wrapper">
    <div id="content" class="pb-10">
      <div
        class="transparent d-flex align-center justify-space-between px-3 py-4"
      >
        <v-btn text @click="back" color="dark" rounded>
          <v-icon small color="dark">
            {{ mdiClose }}
          </v-icon>
          Close
        </v-btn>

        <!-- <v-icon>
          $bubbl-outline
        </v-icon> -->
      </div>

      <!-- We can put the image here? -->
      <v-img
        src="https://i.ibb.co/vvTrvjL/bubbl-space-background-BLACK-with-OTHERS-02.jpg"
        lazy
      ></v-img>
      <p class="grey--text my-1 text-center" style="font-size: 11px;">
        a galaxy of bubbls
      </p>

      <!-- A short intro to Bubbl -->
      <v-subheader class="font-weight-medium headline secondary--text mt-3"
        >A Short Intro to Bubbl</v-subheader
      >

      <div class="px-4">
        <!-- intro -->
        <p class="pa-0 ma-0" id="what-is-this">
          <v-subheader
            class=" font-rubik font-weight-medium deep-purple--text text--darken-2 px-0"
          >
            What is this thing?
          </v-subheader>
          It's a new kind of social-network for AUN. Like a big WhatsApp gc but
          <i>much</i> cooler

          <!-- TODO: ABEG move this to an svg file or a vuetify icon, ejoor! THANK YOU JESUS! -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            focusable="false"
            width="1em"
            height="1em"
            style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 36 36"
          >
            <path
              fill="#269"
              d="M33.398 23.678c-7.562 4.875-20.062-.438-18.375-8.062c1.479-6.684 9.419-4.763 11.225-3.861c1.806.902.713-3.889-3.475-5.327C17.1 4.48 10.156 4.893 7.961 14.678c-1.5 6.687 1.438 16.062 12.719 16.187c11.281.125 12.718-7.187 12.718-7.187z"
            />
            <path
              fill="#55ACEE"
              d="M35.988 25.193c0-2.146-2.754-2.334-4-1.119c-2.994 2.919-7.402 4.012-13.298 2.861c-10.25-2-10.341-14.014-3.333-17.441c3.791-1.854 8.289.341 9.999 1.655c1.488 1.143 4.334 2.66 4.185.752C29.223 7.839 21.262-.86 10.595 4.64C-.071 10.14 0 22.553 0 24.803v7.25C0 34.262 1.814 36 4.023 36h28C34.232 36 36 34.262 36 32.053c0 0-.004-6.854-.012-6.86z"
            /></svg
          >.
        </p>

        <p class="pa-0 ma-0" id="why-should-i-care">
          <v-subheader
            class=" font-rubik font-weight-medium deep-purple--text text--darken-2 px-0"
          >
            But why should I care?
          </v-subheader>
          There's no where on the interwebs that you can find AUN students
          together, in one place creating and sharing content relevant to us
          (well, except Canvas) so we decided to create a Bubbl just for AUN
          students. This is our space, This is our common room.
        </p>
        <!-- 1 -->
        <p class="pa-0 ma-0" id="what-is-bubbl">
          <v-subheader
            class=" font-rubik font-weight-medium deep-purple--text text--darken-2 px-0"
          >
            What is a Bubbl abeg?
          </v-subheader>
          A Bubbl is just a collection of people. Everyone in AUN is a member of
          the AUN Bubbl. This site is a digital representation of that
          community. Bubbls are made up of smaller <em>circles</em>.
        </p>

        <!-- 2 -->
        <p class="pa-0 ma-0" id="what-is-a-circle">
          <v-subheader
            class=" font-rubik font-weight-medium deep-purple--text text--darken-2 px-0"
          >
            Which one is circle now??
          </v-subheader>
          A Circle is a community that is focused on a particular topic or
          interest. People interested in Poetry are part of the
          <router-link class="pink--text text--lighten-2" to="/c/poetry"
            >Poetry circle.</router-link
          >
          If you want to look at AUN memes
          <router-link class="pink--text text--lighten-2" to="/c/memegalore"
            >there's one for that</router-link
          >
          Members create, share and discuss content about that interest. You can
          join as many circles as you want.
        </p>

        <!-- 3 -->
        <p class="pa-0 ma-0" id="other-circles">
          <v-subheader
            class=" font-rubik font-weight-medium deep-purple--text text--darken-2 px-0"
          >
            Hmm, so there is a circle for Marlians??
          </v-subheader>
          Not yet. Bubbl is still very new and there are many Circles yet to be
          created. You can start any kind of Circle you want but you have to
          reach a particular rank tho. It is easy.
        </p>

        <!--4 -->
        <p class="pa-0 ma-0" id="ranks">
          <v-subheader
            class=" font-rubik font-weight-medium deep-purple--text text--darken-2 px-0"
          >
            Rank?
          </v-subheader>
          Yeah, your rank shows how much you participate on Bubbl. You increase
          your rank by creating content and interacting with content. The more
          you participate the higher your rank. New users are still JJC so they
          have the JJC rank (shown by the baby badge). You can see your ranks
          &amp; badges in your profile.
        </p>

        <!-- 6 -->
        <div class="pa-0 ma-0" id="what-to-do-in-a-circle">
          <v-subheader
            class=" font-rubik font-weight-medium deep-purple--text text--darken-2 px-0"
          >
            What can I do exactly in a circle?
          </v-subheader>
          <ul>
            <li>Post content relevant to the Circle</li>
            <li>Vote on the quality of posts</li>
            <li>Discuss posts and other things in the comment section</li>
            <li>Participate in Challenges (coming soon...)</li>
          </ul>
        </div>

        <!-- 7 -->
        <p class="pa-0 ma-0" id="making-money">
          <v-subheader
            class=" font-rubik font-weight-medium deep-purple--text text--darken-2 px-0"
          >
            Okay I grab. Can I make money on Bubbl?
          </v-subheader>
          Not yet... stay tuned :)
        </p>

        <!-- 8 -->
        <div class="pa-0 ma-0" id="team-roles">
          <v-subheader
            class=" font-rubik font-weight-medium deep-purple--text text--darken-2 px-0"
          >
            So who is behind all this?
          </v-subheader>
          It be yer own people:
          <ul>
            <li>
              Nyior Clement <br />
              <blockquote class="blockquote body-2 pl-2 py-2">
                I make sure everything works under the hood
              </blockquote>
            </li>

            <li>
              Moyi Abioye
              <blockquote class="blockquote body-2 pl-2 py-2">
                I ensure that Bubbl looks good
              </blockquote>
            </li>

            <li>
              Milton Doibo
              <blockquote class="blockquote body-2 pl-2 py-2">
                I work with users and make sure they're getting the best Bubbl
                experience
              </blockquote>
            </li>

            <li>
              Daniel Iheonu <br />
              <blockquote class="blockquote body-2 pl-2 py-2">
                I work on the underlying systems
              </blockquote>
            </li>

            <li>
              Emmanuel "LeanKhan" Segun-Lean
              <blockquote class="blockquote body-2 pl-2 py-2">
                I make the cool parts :p
              </blockquote>
            </li>

            <li>
              And
              <a
                href="https://wa.me/2348140760114?text=hi+i+want+to+join+the+bubbl+team"
                class="pink--text text--lighten-2"
                target="_blank"
                >you :)
              </a>
            </li>
          </ul>
        </div>

        <div class="pa-0 ma-0" id="how-can-i-help">
          <v-subheader
            class=" font-rubik font-weight-medium deep-purple--text text--darken-2 px-0"
          >
            Mad mad, how can I help?
          </v-subheader>
          You can help us share this page to spread the message so this place is
          full of students or if you want to assist us more directly,
          <a
            href="https://wa.me/2347056379098"
            class="pink--text text--lighten-2"
            target="_blank"
            >hit Milton up
          </a>
          <v-icon small color="green">
            {{ mdiWhatsapp }}
          </v-icon>
          and tell him what you want to do.
        </div>

        <!-- 9 -->
        <div class="pa-0 ma-0" id="what-next">
          <v-subheader
            class=" font-rubik font-weight-medium deep-purple--text text--darken-2 px-0"
          >
            Sounds exciting, what do I do next?
          </v-subheader>
          <div>
            <v-btn
              to="/circles"
              depressed
              class="white--text"
              color="pink accent-2"
              >Find your Circle</v-btn
            >
          </div>
        </div>

        <!-- end. -->
        <div class="grey--text text-center mt-12">
          <div class="mb-7">
            <p class="mb-2">
              and Spread the message
            </p>

            <social-share
              :url="`https://link.mybubbl.me/intro?from=share`"
              :horizontalIcons="true"
              caption="What is Bubbl about? Find out here"
              description="Find out what Bubbl is about and how it relates to AUN"
              hashtags="aun,bubbl,cool,fun"
            ></social-share>
            <!-- TODO: send events when any of this clicked -->
          </div>
          <router-link to="/">
            <img src="https://i.ibb.co/YPgGPLk/logo.png" height="19px" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialShare from '@/components/helpers/social-share.vue';
import backer from '@/mixins/router-back';
// icons
import { mdiWhatsapp, mdiClose } from '@mdi/js';
export default {
  name: 'Intro',
  data: () => ({
    mdiClose,
    mdiWhatsapp
  }),
  components: {
    SocialShare
  },
  head: {
    title: 'A short intro to Bubbl'
  },
  mixins: [backer],
  mounted() {
    window.scrollTo(0, 0);

    const fromWhere = this.$route.query['from'] || 'direct';

    if (this.$gtag) {
      this.$gtag.event(`visited`, {
        event_label: `From ${fromWhere}`,
        event_category: 'Intro to Bubbl Page'
      });
    }
  }
};
</script>

<style scoped>
body {
  overflow-y: hidden;
}

#wrapper:before {
  content: '';
  float: left;
  height: 100%;
}

#wrapper {
  height: 100%;
  background-color: whitesmoke;
}

#content {
  background-color: whitesmoke;
}

#content::after {
  content: '';
  display: block;
  clear: both;
}

#team-roles span {
  /* color: black; */
  font-weight: bold;
}

/* #team-roles blockquote {
  border-left: solid 3px #9e9e9e !important;
} */
</style>
